import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton, Input, Table, LinkButton, Button, CheckmarkIcon, WarningIcon, AutoCompleteSelect } from "../../../components";
import {
  orgActions, getOrgOrganisers, isOrganiserUpdated, isLoading, isAuditee, getCurrentUser, getOrgActiveUsers, isOrganizer,
  getDesignationList, userActions, commonAction, isUserCreated
} from "../../../store";

const OrgDetailsFields = [
  { width: 8, label: "Brand Name", attr: "brandname", defaultVal: "" },
  { width: 4, label: "Industry", attr: "industry", defaultVal: "" },
  { width: 12, label: "Legal Name", attr: "legalname", defaultVal: "" },
  { width: 8, label: "Email Domain(s)", attr: "email_domain", defaultVal: "" },
  { width: 4, label: "Website", attr: "website", defaultVal: "" },
  { width: 12, label: "Registered Address", attr: "registered_address", defaultVal: "-", isFacility: true },
];
const OrganiserColumns = [
  { label: "Name", width: 'calc(35% - 200px)', align: 'left', attr: 'name', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Email", width: 'calc(45% - 200px)', align: 'left', attr: 'email', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Department", width: '200px', align: 'left', attr: 'department_name', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Designation", width: 'calc(35% - 200px)', align: 'left', attr: 'designation', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Action", width: '180px', type: 'actions', actions: [{ label: 'Update', action: 'nominate' }] },
]
const Columns = [
  { label: "Designation", width: '400px', align: 'left', type: 'html', attr: 'designation', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Name", width: '300px', align: 'left', attr: 'name', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Email", width: 'calc(50% - 400px)', align: 'left', attr: 'email', dataCellClassName: 'c00085', cellFont: 'h11' },
  { label: "Action", width: 'calc(50% - 300px)', type: 'actions', headCellClassName: 'action-head', actions: [{ label: 'Update', action: 'nominate' }] },
]

const InfoField = (props) => {
  const { width, label, value, defaultVal } = props;
  return (
    <Grid sm={12} xs={12} md={width} lg={width} item>
      <div className='col w-100 item'>
        <label className='h11 label'>{label}</label>
        <span className='h7 value'>{value || defaultVal}</span>
      </div>
    </Grid>
  )
}

const getOrganizer = (org) => {
  return (org && org.organizer) ? { id: org.organizer.id, label: org.organizer.name } : '';
}
const NominateUser = (props) => {
  const { user, title } = props;
  return (
    <div className='col w-100 user-sec'>
      {
        Boolean(title) && <div className='h10 exo2 bold title'>{title}</div>
      }
      <div className='row w-100  field'>
        <div className='label h11'>Name:</div>
        <div className='row rest bold h10 proper-case'>{user.name}</div>
      </div>
      <div className='row w-100'>
        <div className='label h11'>Email:</div>
        <div className='row rest link h10'>{user.email}</div>
      </div>
    </div>
  )
}
const NominateDialog = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser)
  const { type, title, selectedCxO, newCxOUser, currentCxOUser, org } = props;
  const [state, setState] = React.useState({ email: '', emailError: '' })
  const isReplace = type === 'replace' || type === 'assign';
  const handleConfirm = (e) => {
    e.preventDefault();
    if (isReplace) {
      let message = !state.email ? 'Please Enter the email' : (state.email.toLowerCase() !== currentUser.email.toLowerCase() ? 'Invalid Email Address' : '')
      if (message.length > 0) {
        dispatch(commonAction.setAlert({ success: false, title: message }))
        return setState((_) => ({ ..._, emailError: true }))
      }
    }
    props.onConfirm && props.onConfirm()
  }
  return (
    <Dialog className='dialog' classes={{ paper: `nominate-dialog` }} open>
      <DialogTitle classes={{ root: 'title row v-start' }}>
        {
          isReplace &&
          <WarningIcon className='warning-icon' />
        }
        <div className={`h6 bold exo2 ${isReplace ? 'cC00' : 'c-primary'}`}>{title}</div>
      </DialogTitle>
      <DialogContent>
        {
          type === 'nominate' &&
          <React.Fragment>
            <p className='h11 text'>Confirm that you wish to update the {selectedCxO} for {org.brandname}. </p>
            <NominateUser user={newCxOUser} />
          </React.Fragment>
        }
        {
          type === 'assign' &&
          <React.Fragment>
            <p className='h10 text c777 exo2'>{newCxOUser.firstname} is already defined as the {newCxOUser.designation} <br /> <br />
              If you confirm the update the {newCxOUser.designation} will become blank!
            </p>
            <NominateUser user={newCxOUser} />
            <div className='row w-100 field'>
              <div className='label-pos bold h10 exo2'>Current Position:</div>
              <div className='row rest h10 exo2'>{newCxOUser.designation}</div>
            </div>
            <div className='row w-100 field'>
              <div className='label-pos bold h10 exo2'>New Position:</div>
              <div className='row rest  h10 exo2'>{selectedCxO}</div>
            </div>
          </React.Fragment>
        }
        {
          type === 'replace' &&
          <React.Fragment>
            <p className='h10 text c777 exo2'>Existing {selectedCxO} will be replaced with new {selectedCxO}. <br />
              Existing & New CxOs will be notified about this change.
            </p>
            <NominateUser title={`Existing ${selectedCxO}`} user={currentCxOUser} />
            <NominateUser title={`New ${selectedCxO}`} user={newCxOUser} />
          </React.Fragment>
        }
        {
          isReplace &&
          <form className='col w-100' onSubmit={handleConfirm}>
            <Input
              className="email-input h10"
              labelClassName='h10 c777 w-100'
              placeholder="Email"
              label='Type your Email Address to confirm this major change…'
              error={state.emailError}
              value={state.email}
              onChange={(e) => {
                setState((_) => ({ ..._, email: e.target.value, emailError: '' }))
              }}
            />
          </form>
        }
      </DialogContent>
      <DialogActions classes={{ spacing: 'action' }}>
        <div className='row w-100 h-btn'>
          <Button onClick={handleConfirm} disableElevation color='#4B4' text="Yes, Confirm" className='h12' endIcon={<CheckmarkIcon className='h12' />} />
          <LinkButton label='Cancel' onClick={props.onClose} />
        </div>
      </DialogActions>
    </Dialog>
  )
}
export const OrgDetails = (props) => {
  const dispatch = useDispatch();
  const org = props.org || {};
  const isUserUpdated = useSelector(isUserCreated)
  const loading = useSelector(isLoading);
  const user = useSelector(getCurrentUser);
  const designations = useSelector(getDesignationList)
  const organisers = useSelector(getOrgOrganisers.bind(null, org.id));
  const isUpdated = useSelector(isOrganiserUpdated);
  const [state, setState] = useState({ showChange: false, selectedDesignation: '', selectedUser: '', showNominateDialog: false });
  const showEdit = props.showEdit === undefined ? false : props.showEdit;
  const showTitle = props.showTitle === undefined ? true : props.showTitle;
  const enableEditOrgUser = props.enableEditOrgUser === undefined ? false : props.enableEditOrgUser;
  const users = useSelector(getOrgActiveUsers.bind(null, org.id, ''))
  const _isAuditee = isAuditee(user);


  useEffect(() => {
    dispatch(orgActions.fetchOrganisers({ orgId: org.id }))
    dispatch(userActions.fetchActiveUsers({ orgId: org.id }));
  }, []);
  useEffect(() => {
    if (isUpdated) {
      setState((_) => ({ ..._, organizer: null, showChange: false }))
      dispatch(orgActions.updateOrganiserSuccess(false))
    }
  }, [isUpdated]);
  useEffect(() => {
    if (isUserUpdated) {
      dispatch(userActions.createUserSuccess(false))
      dispatch(userActions.fetchActiveUsers({ orgId: org.id }));
    }
  }, [isUserUpdated]);
  const handleSeniorMgtUpdate = (row) => {
    let filtered = [], designation;
    if (row) {
      designation = row.designation;
      for (let i = 0; i < users.length; i++) {
        if (users[i].designation !== designation) {
          let user = users[i];
          filtered.push({
            id: user.id, label: user.name, name: user.name, email: user.email, firstname: user.firstname,
            designation: user.designation, short: row.short
          })
        }
      }
    }
    setState((_) => ({ ..._, selectedDesignation: designation, selectedUser: '', filteredUsers: filtered }))
  }
  const handleSaveDesignation = () => {
    const { selectedDesignation, selectedUser } = state;
    if (selectedUser) {
      let dialogData = { newCxOUser: selectedUser };
      let designationOption = designations.find(({ id }) => (id === selectedDesignation))
      let selectedUserCxO = designations.find(({ id }) => (id === selectedUser.designation))
      const currentCxO = users.find(({ designation }) => (designation === selectedDesignation));
      if (currentCxO) {
        dialogData.type = 'replace';
        dialogData.currentCxOUser = currentCxO;
        dialogData.selectedCxO = designationOption.short;
        dialogData.title = `${selectedUser.short} already defined`;
      } else {
        if (selectedUserCxO) {
          dialogData.type = 'assign';
          dialogData.selectedCxO = designationOption.label;
          dialogData.title = `${selectedUser.firstname} is already the ${selectedUserCxO.short}!`;
        } else {
          dialogData.type = 'nominate';
          dialogData.selectedCxO = designationOption.short;
          dialogData.title = `Nominate ${designationOption.short}`;
        }
      }
      setState((_) => ({ ..._, showNominateDialog: true, dialogData: dialogData }))
    };
  }
  const handleConfirmNominate = () => {
    const { selectedDesignation, selectedUser } = state;
    setState((_) => ({ ..._, showNominateDialog: false, dialogData: null, selectedDesignation: '' }))
    const body = { ent_usr: { designation: selectedDesignation }, user_id: Number(selectedUser.id) }
    dispatch(userActions.updateUser(body))
  }
  const handleSeniorManagementActionCellRender = (row) => {
    return (
      state.selectedDesignation === row.designation ?
        <div className='row w-100 update-cell'>
          <div className='col'>
            <AutoCompleteSelect
              className="h10"
              placeholder='Search User by Name'
              value={state.selectedUser}
              options={state.filteredUsers}
              onChange={(e) => setState((_) => ({ ..._, selectedUser: e.target.value }))}
            />
          </div>
          <PrimaryButton
            font='h12'
            text='Save'
            onClick={handleSaveDesignation}
          />
          <Button
            font='h12'
            text='Cancel'
            onClick={() => handleSeniorMgtUpdate(null)}
          />
        </div>
        :
        <LinkButton
          className={`h10 proper-case update-btn`}
          label='Update'
          disabled={Boolean(state.selectedDesignation)}
          onClick={() => handleSeniorMgtUpdate(row)} />
    )
  }
  const handleOrganiserActionCellRender = (row) => {
    return (
      <LinkButton
        className={`h10 proper-case update-btn`}
        label='Update'
        onClick={() => props.onUpdateUser && props.onUpdateUser(row.id)}
      />
    );
  };
  const getSeniorMgtColumns = () => {
    let columns = Columns.map((_) => ({ ..._ }));
    columns[columns.length - 1].render = handleSeniorManagementActionCellRender
    //remove the last column of actions for the auditee
    const filteredColumns = _isAuditee ? columns.slice(0, -1) : columns;
    return filteredColumns;
  }
  const getOrganisersColumns = () => {
    let columns = OrganiserColumns.map((_) => ({ ..._ }));
    columns[columns.length - 1].render = handleOrganiserActionCellRender;
    //remove the last column of actions for the auditee
    const filteredColumns = _isAuditee ? columns.slice(0, -1) : columns;
    return filteredColumns;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const { organizer } = state;
    dispatch(orgActions.updateOrganiser({ orgId: org.id, userId: organizer.id }))
  }
  const getInformationFields = () => {
    if (_isAuditee) {
      return [OrgDetailsFields[0]]
    }
    return OrgDetailsFields
  }
  const getSeniorManagementUsers = () => {
    let filtered = (designations || []).map((designation) => {
      let clone = { designation: designation.label, short: designation.short };
      let user = users.find((user) => (user.designation === designation.id));
      if (user) {
        clone = { ...clone, ...user }
      }
      return clone;
    });
    return filtered;
  };
  const getOrganisers = () => {
    let filtered = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (isOrganizer(user)) {
        const { department_name, name, email, designation, id } = user;
        filtered.push({ department_name, name, email, designation, id })
      }
    }
    return filtered;
  };
  // const handleOrganiserUpdate = (action, row) => {
  //   props.onUpdateUser && props.onUpdateUser(row.id);
  // }

  return (
    <div className={`col w-100 orgd ${props.className || ''}`}>

      <div className='row w-100 v-start'>
        <div className='col f-rest'>
          {
            showTitle && <h5 className='h5 exo2 med title'>Information</h5>
          }
          <div className='sec w-100'>
            <Grid container columnSpacing={2}>
              {
                getInformationFields().map((_) => {
                  let value = _.isFacility ? org.facility[_.attr] : org[_.attr];
                  return (
                    <InfoField key={_.attr} {..._} value={value} />
                  )
                })
              }
            </Grid>
          </div>
        </div>
        {
          showEdit && <PrimaryButton text='Edit Details' onClick={props.onEdit} />
        }
      </div>
      <div className='col w-100'>
        {
          Array.isArray(users) &&
          <React.Fragment>
            <div className='col user-sec'>
              <h5 className='h5 exo2 med title'>Senior Management</h5>
              <div className='sec w-100'>
                <Table
                  columns={getSeniorMgtColumns()}
                  className='w-100 h-100 org-col-wrap'
                  rows={getSeniorManagementUsers()}
                  // onAction= {handleSeniorMgtUpdate}
                />
              </div>
            </div>
            <div className='col user-sec'>
              <h5 className='h5 exo2 med title'>Organiser(s)</h5>
              <div className='sec w-100'>
                <Table
                  columns={getOrganisersColumns()}
                  className='w-100 h-100 org-col-wrap organiser-tbl'
                  rows={getOrganisers()}
                // onAction={handleOrganiserUpdate}
                />
              </div>
            </div>
          </React.Fragment>
        }
      </div>
      {
        Boolean(state.showNominateDialog) &&
        <NominateDialog
          org={org}
          {...state.dialogData}
          onConfirm={handleConfirmNominate}
          onClose={() => setState((_) => ({ ..._, showNominateDialog: false }))}
        />
      }
    </div>
  )
}