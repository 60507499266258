import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Popover, Typography, TextField } from "@mui/material";
import TextButton from '@mui/material/Button';
import { LockReset, HighlightOff } from '@mui/icons-material';
import { OrgDetails } from "../Components/Details";
import { PrimaryButton, Button, ConfirmationBox, } from "../../../components";
import { orgActions, getOrgDetailsById, isAdmin, isOrganizer, isArchitect, getCurrentUser, getResetCaptcha } from "../../../store";

export default (props) => {
  const { orgId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ showEnable: false, anchorResetEl: null, openResetDialog: false, captchaValue: '', orgValue: '' });
  const captcha = useSelector(getResetCaptcha)
  const isSuperUser = isAdmin(user) || isArchitect(user)
  const handleEdit = () => {
    navigate(`/O/${org.id}/edit`)
  }
  const handleUserUpdate = (userId) => {
    navigate(`/O/${org.id}/user/${userId}/edit`);
  }
  const handleReEnable = (val) => {
    setState((_) => ({ ..._, showEnable: val }))
  }
  const handleReEnableOrg = () => {
    dispatch(orgActions.enableOrDisableOrg({ orgId: orgId, enabling: org.is_disabled }))
    handleReEnable(false)
  }
  //functions related to reset default popup
  const getCaptcha = () => {
    dispatch(orgActions.fetchCaptchaForReset())
  }

  const handleDisplayResetDropdown = (event) => {
    setState((_) => ({ ..._, anchorResetEl: event.currentTarget }))
  }
  const handleResetDropdownClose = () => {
    setState((_) => ({ ..._, anchorResetEl: null }))
  };
  const open = Boolean(state.anchorResetEl);
  const id = open ? 'simple-popover' : undefined;

  const handleResetDialogOpen = () => {
    setState((_) => ({ ..._, openResetDialog: true }))
    getCaptcha()
  }
  const handleResetDialogClose = () => {
    setState((_) => ({ ..._, openResetDialog: false, anchorResetEl: null, captchaValue: '', orgValue: '' }))
  };

  const handleCaptchaChange = (e) => {
    setState((_) => ({ ..._, captchaValue: e.target.value }))
  }
  const handleOrgChange = (e) => {
    setState((_) => ({ ..._, orgValue: e.target.value }))
  }
  console.log('superuser ',isSuperUser, 'brandname ',org.brandname)
  const org_email_domains = org.email_domain.split(',')
  const isTextButtonActive = state.captchaValue.trim() !== '' && state.captchaValue === captcha && state.orgValue.trim() !== '' && org_email_domains.includes(state.orgValue);
  const handleReset = () => {
    dispatch(orgActions.verifyCaptchaEmailDomain({ "captcha": state.captchaValue, "email_domain": state.orgValue }))
    setState((_) => ({ ..._, openResetDialog: false, anchorResetEl: null, captchaValue: '', orgValue: '' }))
  }
  return (
    <div className='org-int-det w-100 h-100 oy-auto'>
      <Grid container spacing={0} direction="row-reverse">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='btn-sec'>
            <div style={{marginLeft:'150px'}}><PrimaryButton text="Edit Details" className='w-100 btn-edit' onClick={handleEdit} /></div>
            <div style={{ width: '30px' }}></div>
            <div>
              {isSuperUser && org.brandname === "Luciance" &&
                <>
                  <div className='row h-end'> <PrimaryButton font='h10' text='...' color='blue' onClick={handleDisplayResetDropdown} /></div>
                  <div className='row w-100 h-end mb-5'>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={state.anchorResetEl}
                      onClose={handleResetDropdownClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Typography sx={{ px: 3, py: 1.5 }}>
                        <div className='v-ctr row cFF3333 h12 exo2 bold' onClick={handleResetDialogOpen}>
                          <LockReset className='mr-1' /> Reset
                        </div>
                      </Typography>
                    </Popover>
                    {
                      state.openResetDialog &&
                      <>
                        <Dialog
                          open={open}
                          onClose={handleResetDialogClose}
                          PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                              event.preventDefault();
                              const formData = new FormData(event.currentTarget);
                              const formJson = Object.fromEntries(formData.entries());
                              const email = formJson.email;
                              console.log(email);
                              handleResetDialogClose();
                            },
                          }}
                        >
                          <DialogTitle className='cFF3333 f18 exo2 bold'>Reset to Default?</DialogTitle>
                          <DialogContent>
                            <DialogContentText className=''>
                              <div className='c00 f16'>This action will reset this organisation to its default state.<br />Are you sure that you want to proceed?</div>
                              <div className='row h-ctr mt-1 h6 exo2'> {captcha} </div>
                              <div className='row h-btn v-ctr c00 mt-1 mr-1 f16'>Enter the characters displayed above
                                <div className='ml-1'><TextField variant="outlined" InputProps={{ style: { fontSize: '1.4rem' } }} onChange={handleCaptchaChange} /></div>
                              </div>
                              <div className='row h-btn v-ctr c00 mt-1 mr-1 f16'>Enter the organisation's primary domain
                                <div className='ml-1'><TextField variant="outlined" InputProps={{ style: { fontSize: '1.4rem' } }} onChange={handleOrgChange} /></div>
                              </div>
                              <hr className='mt-1' />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <div style={{ marginTop: '-20px', paddingRight:"25px" }} className='row'>
                              <TextButton
                                variant="text"
                                startIcon={<LockReset />}
                                className={`h12 exo2 ${isTextButtonActive ? 'cFF3333' : ''}`}
                                disabled={!isTextButtonActive}
                                onClick={handleReset}
                                style={{ textTransform: 'none', fontFamily:'Exo 2' }}
                              >Reset
                              </TextButton>
                              <div className='mr-1'></div>
                              <PrimaryButton font='h12' className='exo2' startIcon={<HighlightOff />} text='Cancel' color='blue' onClick={handleResetDialogClose} />
                            </div>
                          </DialogActions>
                        </Dialog>
                      </>
                    }
                  </div>
                </>
              }
            </div>

            {
              (org.is_disabled) &&
              <Button color='#00CC55' disableElevation text="Re-Enable" className='btn-enable' onClick={() => handleReEnable(true)} />
            }
          </div>
          {
            Boolean(org) &&
            <OrgDetails
              org={org}
              showEdit={false}
              showTitle={false}
              onUpdateUser={handleUserUpdate}
              enableEditOrgUser={isAdmin(user) || isOrganizer(user) || isArchitect(user)} />
          }
        </Grid>
      </Grid>
      {
        state.showEnable &&
        <ConfirmationBox
          closeOnEnter
          row={false}
          className=''
          titleClassName='c00CC55'
          btnCancel={{
            onClick: () => handleReEnable(false), text: 'Go back', type: 'link'
          }}
          btnOK={{
            text: 'Activate',
            color: '#00CC55',
            onClick: handleReEnableOrg
          }}
          title='Activate Organisation'
          subtitle='Activating the organisation will allow all permitted actions to be taken within Fusion'
        />
      }
    </div>
  )
}