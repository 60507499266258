import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, requestHandler, setAlertAndLoading, getDateInFormat, sortByMapping, sortByDate } from "../common";
import { axios } from "../../utils/axios";
import { orgActions } from "./slice";

export const getUser = (state) => state.user;
const getStatusList = state => state.assessment && state.assessment.statusList

const getOrgMap = (_) => {
  const { pending_asmts_count, complete_asmts_count, total_asmts_count } = _.org_detailed_info
  return {
    ..._, ...(_.org_detailed_info),
    assessmens: { pending: pending_asmts_count, completed: complete_asmts_count, total: total_asmts_count }
  }
}
const _fetchOrgDetailsById = async (orgId) => {
  let org = await axios.get('/consoles/org_detail?org_id=' + orgId);
  const organizer = await axios.post('/consoles/get_organiser', { org_id: org.id });
  if (organizer && organizer.organiser) {
    org.organizer = { ...organizer.organiser, name: `${organizer.organiser.firstname} ${organizer.organiser.lastname}` }
  }
  return org;
}

function checkOrgUserExist(email) {
  const body = { user: { email: email } };
  return axios.post('/consoles/check_if_org_usr_exists', body)
}

function* getUserOrg() {
  yield requestHandler(function* () {
    let state = yield select(getUser), org = 'NotExist';
    if (state.currentUser.email) {
      const isOrg = yield checkOrgUserExist(state.currentUser.email);
      if (isOrg.org) {
        org = yield _fetchOrgDetailsById(state.currentUser.ent_org_id);
      }
      yield put(orgActions.getUserOrgSuccess(org));
    }
  });
}
function mapCallbackAcceptedMap(orgId, _) {
  const { customer, vendor, mapped_user, org_mapping } = _;
  if (customer && vendor && org_mapping && mapped_user) {
    const org = orgId === vendor.id ? customer : vendor
    return {
      brandname: org.brandname,
      orgId: org.id,
      organiserName: `${mapped_user.firstname} ${mapped_user.lastname || ''}`,
      organiserEmail: mapped_user.email,
      mappingDate: getDateInFormat(org_mapping.updated_at),
      org_mapping: org_mapping
    }
  }
  return null
}
function mapCallbackPendigMap(orgId, _) {
  const { requester, customer, vendor, mapped_user, org_mapping, show_activate_deactivate_button } = _;
  if (vendor && org_mapping && mapped_user) {
    const row = {
      org_mapping: org_mapping,
      requestDateTime: getDateInFormat(org_mapping.updated_at),
      requester: requester ? `${requester.firstname || ''} ${requester.lastname || ''}` : ''
    }
    if (orgId === vendor.id) {
      row.brandname = customer ? customer.brandname : (mapped_user.email || '').split("@")[1]
    } else {
      row.brandname = vendor.brandname
    }
    if (show_activate_deactivate_button) {
      row.actions = [{ label: 'Accept', action: 'accept' }, { label: 'Reject', action: 'reject' }]
    } else {
      row.actions = org_mapping.status ? org_mapping.status : org_mapping.activated ? 'Accepted' : 'Pending'
    }
    return row
  }
  return null
}
function* fetchAcceptedOrgMappings() {
  yield requestHandler(function* () {
    let { accepted_org_list } = yield axios.get('/consoles/accepcted_org_list');
    let state = yield select(getUser)
    let res = [];
    Object.values(accepted_org_list).forEach((_) => {
      const row = mapCallbackAcceptedMap(state.currentUser.ent_org_id, _)
      if (row) {
        res.push(row)
      }
    })
    res.sort(sortByMapping)
    yield put(orgActions.getAcceptedOrgsSuccess(res));
  });
}
function* fetchPendingOrgMappings() {
  yield requestHandler(function* () {
    let { pending_org_list } = yield axios.get('/consoles/pending_org_list');
    let state = yield select(getUser)
    let res = [];
    Object.values(pending_org_list || {}).forEach((_) => {
      const row = mapCallbackPendigMap(state.currentUser.ent_org_id, _)
      if (row) {
        res.push(row)
      }
    })
    res.sort(sortByMapping)
    yield put(orgActions.fetchPendingOrgsSuccess(res));
  });
}
function* fetchOrgMappings({ payload }) {
  yield requestHandler(function* () {
    const { orgId } = payload;
    let { accepted_org_list, pending_org_list } = yield axios.get('/consoles/specific_pen_acc_org_list?org_id=' + orgId);
    let pending = [];
    Object.values(pending_org_list || {}).forEach((_) => {
      const row = mapCallbackPendigMap(orgId, _)
      if (row) {
        pending.push(row)
      }
    })
    let accepted = [];
    Object.values(accepted_org_list || {}).forEach((_) => {
      const row = mapCallbackAcceptedMap(orgId, _)
      if (row) {
        accepted.push(row)
      }
    })
    pending.sort(sortByMapping)
    accepted.sort(sortByMapping)
    yield put(orgActions.getAcceptedOrgsSuccess(accepted));
    yield put(orgActions.fetchPendingOrgsSuccess(pending));
  })
}
function* isOrgUserExist({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    let res = { isOrgExist: false, isMappingExist: false }
    const { currentUserOrg, email } = payload
    const { org, user } = yield checkOrgUserExist(email);
    if (org) {
      res.isOrgExist = true
      const body = { vendor_id: currentUserOrg, customer_id: org.id }
      const { vendor, customer, mapping } = yield axios.post('/consoles/mapping_exists', body);
      if (vendor && customer) {
        res.isMappingExist = mapping && mapping.activated;
      }
    }
    yield setAlertAndLoading(false);
    yield put(orgActions.isOrgUserExistSuccess(res));
  });
}
function* fetchIndustryList({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {}
    let res = yield axios.get('/consoles/industry_list', header);
    res = res.map((_) => ({ label: _, id: _ }));
    yield put(orgActions.fetchIndustryListSuccess(res));
  });
}
function* fetchDepartments({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {}
    let res = yield axios.get('/consoles/department_list', header);
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    yield put(orgActions.fetchDepartmentSuccess(res));
  });
}
function* fetchDesignations({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {}
    let res = yield axios.get('/consoles/designation_list', header);
    res = (res.designations || []).map((_) => {
      const regex = /\[(.*?)\]/, match = regex.exec(_);
      const short = match ? match[1] : _;
      return { label: _, id: _, short }
    });
    yield put(orgActions.fetchDesignationsSuccess(res));
  });
}
function* fetchCountryList({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {}
    let res = yield axios.get('/consoles/country_list', header);
    res = res.map((_) => ({ label: _, id: _ }));
    yield put(orgActions.fetchCountryListSuccess(res));
  });
}
function* createNewOrg({ payload }) {
  yield requestHandler(function* () {
    const { org, seniorUsers } = payload;
    yield setAlertAndLoading(true, null, true);
    let orgRes = yield axios.post('/consoles', org, { ...axios.getFormDataContentType() });
    if (Array.isArray(seniorUsers) && seniorUsers.length > 0) {
      for (let i = 0; i < seniorUsers.length; i++) {
        let user = seniorUsers[i];
        user.ent_org_id = orgRes.id;
        yield axios.post('/consoles/create_org_user', { ent_usr: user });
      }
    }
    yield put(orgActions.createOrUpdateOrgSuccess({ org: orgRes }));
    yield put(orgActions.createOrUpdateOrgStatus({ create: true, updated: false }));
    const message = {
      title: "Organization Successfully created",
      subtitle: "The changes made to the organization have been saved successfully!"
    }
    yield setAlertAndLoading(false, message, false);
  });
}
function* creatOrUpdateOrg({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { organiser, org, onboard, header } = payload
    let isCreate = !Boolean(org.get('ent_org_id')), message = null;
    let orgRes = yield axios.post('/consoles/create_or_updare_org', org, { ...axios.getFormDataContentType(), ...(header || {}) });
    if (organiser) {
      if (onboard) {
        yield axios.post('/consoles/update_org_user', organiser, (header || {}));
      } else {
        organiser.ent_org_id = orgRes.id
        yield axios.post('/consoles/create_organiser', { ent_usr: organiser });
      }
    }
    yield put(orgActions.createOrUpdateOrgSuccess({ org: orgRes }));
    yield put(orgActions.createOrUpdateOrgStatus({ create: isCreate, updated: !isCreate }));
    if (!isCreate) {
      message = {
        title: "Organization Successfully updated",
        subtitle: "The changes made to the organization have been saved successfully!"
      }
    }
    yield setAlertAndLoading(false, message);
  });
}
function* fetchOrgById({ payload }) {
  yield requestHandler(function* () {
    let res = yield _fetchOrgDetailsById(payload.orgId);
    yield put(orgActions.fetchOrgByIdSuccess({ org: res, orgId: payload.orgId }));
  });
}
function* fetchOrgs() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/org_list');
    res.active_orgs = res.active_orgs.map(getOrgMap);
    res.disable_orgs = res.disable_orgs.map(getOrgMap);
    res.active_orgs.sort(sortByDate.bind(null, 'created_at'))
    res.disable_orgs.sort(sortByDate.bind(null, 'updated_at'))
    yield put(orgActions.fetchOrgSuccess(res));
  });
}
function* fetchOrganisers({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/org_organiser_list?org_id=' + payload.orgId);
    let organisers = res.organiser_list.map((_) => ({ id: _.id, label: `${_.firstname} ${_.lastname}`, email: _.email }))
    yield put(orgActions.fetchOrganisersSuccess({ orgId: payload.orgId, organisers: organisers }));
  });
}
function* updateOrganiser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true, null);
    const body = { org_id: payload.orgId, organizer_id: payload.userId }
    yield axios.post('/consoles/update_organiser', body);
    let state = yield select(getUser);
    if (state.currentUser.ent_org_id === payload.orgId) {
      yield put(orgActions.getUserOrg())
    } else {
      yield put(orgActions.fetchOrgById({ orgId: payload.orgId }))
    }
    let message = {
      title: "Organization User updated",
      subtitle: "The changes made to the organization have been saved successfully!"
    }
    yield put(orgActions.updateOrganiserSuccess(true))
    yield setAlertAndLoading(false, message);
  });
}

function* enableOrDisableOrg({ payload }) {
  yield requestHandler(function* () {
    const { enabling, orgId, password } = payload;
    yield setAlertAndLoading(null, null, true);
    let message;
    if (password) {
      const { current_user } = yield axios.post('/consoles/valid_password', { current_password: password });
      if (!current_user) {
        message = {
          title: `Invalid Password`,
        }
        yield setAlertAndLoading(null, message, false);
        return
      }
    }
    const res = yield axios.get('/consoles/enable_disable_org?org_id=' + orgId);
    yield put(orgActions.fetchOrgById({ orgId: orgId }))
    message = {
      title: `Organization ${enabling ? 'Enabled' : 'Disabled'}`,
      subtitle: "The changes made to the organization have been saved successfully!"
    }
    // yield put(orgActions.enableOrDisableOrgSuccess(true))
    yield setAlertAndLoading(null, message, false);
  });
}

function* fetchOrgAsmtModels({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.post('/consoles/specific_org_details', { org_id: payload.orgId });
    res.asmts.sort(sortByDate.bind(null, 'created_at'))
    const statusList = yield select(getStatusList);
    res.asmts = res.asmts.map((_m) => {
      const status = statusList.find((_) => _.id === _m.audit_status)
      return {
        ..._m, created_at: getDateInFormat(_m.created_at),
        controls: { asmt_status_details: _m.asmt_status_details },
        total_cntrls: _m.asmt_status_details.total_cntrls_count,
        asmt_status: status
      }
    })
    res.subscribed_models.sort(sortByDate.bind(null, 'created_at'))
    res.subscribed_models = res.subscribed_models.map((_m) => {
      return { ..._m, ..._m.lib1_model, created_at: getDateInFormat(_m.created_at) }
    })
    yield put(orgActions.fetchOrgByIdSuccess({ org: { id: res.id, asmts: res.asmts, models: res.subscribed_models } }));
  });
}
function* setModelStatus({ payload }) {
  try {
    const { asmt, orgId } = payload
    const org = yield select((s) => {
      return s.org.orgs && s.org.orgs[Number(orgId)]
    });
    if (org) {
      const statusList = yield select(getStatusList);
      const asmt_status = statusList.find((_) => _.id === asmt.audit_status)
      let asmts = org.asmts.map((_) => _);
      const index = asmts.findIndex((_) => _.id === asmt.id)
      asmts[index] = { ...asmts[index], ...asmt, asmt_status }
      yield put(orgActions.fetchOrgByIdSuccess({ org: { id: orgId, asmts: asmts }, orgId: orgId }));
    }
  } catch (err) {
    console.log('err', err);
  }
}
function* fetchOrgSSO({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/get_sso?org_id=' + payload.orgId);
    yield put(orgActions.fetchOrgByIdSuccess({ org: { id: payload.orgId, sso: res.sso || {} } }));
  });
}
function* updateOrgSSO({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    const { adfs, orgId } = payload;
    const body = { adfs: { ...adfs, ent_org_id: orgId } }
    let res = yield axios.post('/consoles/update_adfs', body);
    yield put(orgActions.fetchOrgByIdSuccess({ org: { id: orgId, sso: res.sso || {} } }));
    let message = {
      title: `SSO Updated`,
      subtitle: "The changes made to the organization have been saved successfully!"
    }
    yield setAlertAndLoading(null, message, false);
  });
}
function* activateDeactivateOrgMapping({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    let res = yield axios.post('/consoles/activate_deactivate', payload);
    let message = {
      title: `Organization Mapping`,
      subtitle: "The changes made to the organization mapping have been saved successfully!"
    }
    yield setAlertAndLoading(null, message, false);
    yield put(orgActions.fetchPendingOrgMappings());
    yield put(orgActions.fetchAcceptedOrgMappings());
  });
}
function* fetchNotifyFreqList() {
  yield requestHandler(function* () {
    let res = yield axios.get('/tasks/notification_frequency_list');
    const list = res.noti_frequency_list.map(_ => ({ id: _, label: _ }))
    yield put(orgActions.freqListSuccess(list));
  });
}
function* setOrgConfig({ payload }) {
  yield requestHandler(function* () {
    const { notify, multiAuth, orgId } = payload;
    const body = { org_id: orgId, config: { notification_frequency: notify, otp_required: multiAuth } }
    const res = yield axios.post('/consoles/update_config', body);
    let message = { title: 'Organization Configuration', subtitle: '' }
    if (res.ent_org) {
      message.subtitle = 'The changes made to the organization have been saved successfully!'
      yield setAlertAndLoading(null, message, false);
    } else {
      throw { data: { message: 'Failed to update configuration' } }
    }
  })
}
function* fetchContinuerRespondersList() {
  yield requestHandler(function* () {
    const response = yield axios.get('continuers/con_dept_responders');
    if (response && response.dept_responders) {
      const responders_list = []
      for (let responder of response.dept_responders) {
        if (Boolean(responder.ent_usr_id)) {
          responders_list.push(responder.ent_usr_id)
        }
      }
      yield put(orgActions.setContinuerResponders(responders_list))
    } else {
      throw { data: { message: 'Failed to fetch continuer responders' } }
    }
  })
};
function* fetchInvestigatorRespondersList() {
  yield requestHandler(function* () {
    const response = yield axios.get('incidents/inc_dept_responders');
    if (response && response.dept_responders) {
      const responders_list = []
      for (let responder of response.dept_responders) {
        if (Boolean(responder.ent_usr_id)) {
          responders_list.push(responder.ent_usr_id)
        }
      }
      yield put(orgActions.setInvestigatorResponders(responders_list))
    } else {
      throw { data: { message: 'Failed to fetch Investigator responders' } }
    }
  })
};
function* fetchCaptchaForReset() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/generate_captcha');
    if (res) {
      yield put(orgActions.fetchResetCaptchaSuccess(res.captcha));
    } else {
      throw { data: { message: 'Failed to generate captcha' } }
    }
  })
}
function* verifyCaptchaEmailDomain({ payload }) {
  let { captcha, email_domain } = payload
  let body = { "captcha": captcha, "email_domain": email_domain }
  yield requestHandler(function* () {
    let res = yield axios.post('/centrals/verify_captcha_domain', body);
    let message = { title: '', success: true }
    if (res) {
      res = yield axios.get('/centrals/reset_transaction')
      message.title = "Reset Transactions initiated."
      message.logoutSession = true;
    } else {
      message.title = "Invalid Captcha";
      message.success = false
    }
    yield setAlertAndLoading(null, message, false);
  })
}
SagaRegistry.register(function* orgSaga() {
  yield takeEvery("org/getUserOrg", getUserOrg);
  yield takeEvery("org/fetchOrgMappings", fetchOrgMappings);
  yield takeEvery("org/fetchAcceptedOrgMappings", fetchAcceptedOrgMappings);
  yield takeEvery("org/fetchPendingOrgMappings", fetchPendingOrgMappings);
  yield takeEvery("org/activateDeactivateOrgMapping", activateDeactivateOrgMapping);
  yield takeEvery("org/isOrgUserExist", isOrgUserExist);
  yield takeEvery("org/fetchIndustryList", fetchIndustryList);
  yield takeEvery("org/fetchDepartments", fetchDepartments);
  yield takeEvery("org/fetchDepartments", fetchDesignations);
  yield takeEvery("org/fetchCountryList", fetchCountryList);
  yield takeEvery("org/creatOrUpdateOrg", creatOrUpdateOrg);
  yield takeEvery("org/createNewOrg", createNewOrg);
  yield takeEvery("org/fetchOrgById", fetchOrgById);
  yield takeEvery("org/fetchOrgs", fetchOrgs);
  yield takeEvery("org/fetchOrganisers", fetchOrganisers);
  yield takeEvery("org/updateOrganiser", updateOrganiser);
  yield takeEvery("org/enableOrDisableOrg", enableOrDisableOrg);
  yield takeEvery("org/fetchOrgAsmtModels", fetchOrgAsmtModels);
  yield takeEvery("org/setModelStatus", setModelStatus);
  yield takeEvery("org/fetchOrgSSO", fetchOrgSSO);
  yield takeEvery("org/updateOrgSSO", updateOrgSSO);
  yield takeEvery("org/fetchNotifyFreqList", fetchNotifyFreqList);
  yield takeEvery("org/setOrgConfig", setOrgConfig);
  yield takeEvery("org/fetchContinuerRespondersList", fetchContinuerRespondersList);
  yield takeEvery("org/fetchInvestigatorRespondersList", fetchInvestigatorRespondersList);
  yield takeEvery("org/fetchCaptchaForReset", fetchCaptchaForReset);
  yield takeEvery("org/verifyCaptchaEmailDomain", verifyCaptchaEmailDomain);
})