import "./saga";
export * from "./slice";

const getOrgState = (state) => state.org || {};
const pendingfilterProps = ["brandname", "requestDateTime", "actions", "requester"]
const acceptedfilterProps = ["brandname", "mappingDate", "organiserEmail", "organiserName"]

const filterOrgHelper = (orgs, search, filterProps) => {
  if (Array.isArray(orgs) && search) {
    return orgs.filter((org) => {
      return filterProps.filter(prop => {
        return String(org[prop]).toLowerCase().includes(String(search).toLowerCase())
      }).length > 0;
    })
  } else {
    return orgs;
  }
}
export const getCurrentUserOrg = (state) => {
  const _state = getOrgState(state);
  return _state.userOrg
}
export const isOrgUserExist = (_state) => {
  const state = getOrgState(_state);
  return state.isOrgUserExist;
}
export const getIndustryList = (_state) => {
  const state = getOrgState(_state);
  return state.industryList;
}
export const getCountryList = (_state) => {
  const state = getOrgState(_state);
  return state.countryList;
}
export const getDepartmentsList = (_state) => {
  const state = getOrgState(_state);
  return state.departments || [{ label: 'InfoSec', id: 'InfoSec' }];
}
export const getDesignationList = (_state) => {
  const state = getOrgState(_state);
  return state.designations || [];
}
export const isOrgCreateSuccess = (_state) => {
  const state = getOrgState(_state);
  return state.createOrgSuccess;
}
export const isOrgUpdateSuccess = (_state) => {
  const state = getOrgState(_state);
  return state.updateOrgSuccess;
}
export const getOrgDetailsById = (orgId, _state) => {
  const state = getOrgState(_state);
  let org = state.orgs[orgId];
  if (!Boolean(state.orgs[orgId]) && Array.isArray(state.orgList) && state.orgList.length > 0) {
    org = state.orgList.find((_) => (_.id === orgId || _.guid === orgId));
  } else if (state.userOrg && state.userOrg.id == orgId) {
    org = state.userOrg;
  }
  return org;
}
export const getPendingOrgs = (search, _state) => {
  const state = getOrgState(_state);
  return filterOrgHelper(state.pendingOrgs, search, pendingfilterProps);
}
export const getAcceptedOrgs = (search, _state) => {
  const state = getOrgState(_state);
  return filterOrgHelper(state.acceptedOrgs, search, acceptedfilterProps);
}
export const getAllOrgs = (_state) => {
  const state = getOrgState(_state);
  return state.orgList;
}

export const getOrgOrganisers = (orgId, _state) => {
  const state = getOrgState(_state);
  return state.organisers[orgId];
}
export const isOrganiserUpdated = (_state) => {
  const state = getOrgState(_state);
  return state.updateOrganiserSuccess;
}
export const getOrgSSO = (orgId, state) => {
  const _state = getOrgState(state);
  let sso;
  if (_state.orgs[orgId]) {
    sso = _state.orgs[orgId].sso
  } else if (_state.userOrg && _state.userOrg.id === orgId) {
    sso = _state.userOrg.sso;
  }
  return sso;
}
export const getNotificationFreqs = (state) => {
  const _state = getOrgState(state);
  return _state.notificationTypes
}
export const continuerResponderList = (state) => {
  const _state = getOrgState(state);
  return _state.continuerResponders
}
export const investigatorResponderList = (state) => {
  const _state = getOrgState(state);
  return _state.investigatorResponders
}
export const getResetCaptcha = (state) => {
  const _state = getOrgState(state);
  return _state.resetCaptcha
}